import { graphql } from 'gatsby'
import NewPosts from '@/components/shared/Category/NewPosts'
import SEO from '@/components/shared/SEO'
import SeriesSlide from '@/components/shared/Series/SeriesSlide'
import UCImage from '@/components/shared/UCImage'
import RecommendPosts from '@/components/shared/Category/RecommendPosts'
import { emptyTopicDescription } from '@/constants'
import Header from '@/components/shared/Category/Header'

const Series = (props: any) => {
  const { name, slug } = props.pageContext
  const seriesCategoryId: number = props.pageContext.databaseId
  const posts = props.data.allWpPost.nodes
  const isEmptySeries = posts.length <= 0
  const otherSeries = props.data.allSeriesCategory.nodes.filter(
    (series: any) => series.slug !== slug
  )
  const description = isEmptySeries
    ? emptyTopicDescription(name)
    : props.pageContext.description
  const graphqlArgs: string = isEmptySeries
    ? `where: {categoryNotIn: [515, 518]}`
    : `where: {categoryNotIn: [515, 518], categoryIn: [${seriesCategoryId}]}`

  return (
    <>
      <SEO title={name} description={description} />
      <div className="flex flex-col overflow-hidden">
        <Header name={name} description={description} isEmpty={isEmptySeries} />

        {!isEmptySeries ? (
          <>
            <NewPosts posts={posts} />
            <hr />
            <RecommendPosts graphqlArgs={graphqlArgs} />
          </>
        ) : (
          <>
            <div className="py-10" />
            <UCImage />
            <div className="flex flex-col base-container">
              <h1 className="section-heading">Tìm hiểu Series khác</h1>
              <SeriesSlide series={otherSeries} />
            </div>
            <hr />
            <RecommendPosts graphqlArgs={graphqlArgs} />
          </>
        )}
      </div>
    </>
  )
}

export const query = graphql`
  query($id: String) {
    allWpPost(
      filter: {
        categories: {
          nodes: {
            elemMatch: { id: { eq: $id }, parentDatabaseId: { ne: 515 } }
          }
        }
      }
    ) {
      nodes {
        title
        slug
        excerpt
        modified(formatString: "DD-MM-YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.33)
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
            parentDatabaseId
          }
        }
      }
    }
    allSeriesCategory: allWpCategory(
      filter: { parentDatabaseId: { eq: 348 } }
    ) {
      nodes {
        name
        slug
        parentDatabaseId
      }
    }
  }
`

export default Series
